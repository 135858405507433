import React, { createRef } from "react"
import config from "../../config.ts";
import { tryGetValueOfQrCode } from "../../route.ts";
import { AppTools, ConnectState } from "../../types.ts";
import { useConnect } from "../../hooks";
import { EnterPin } from "../../pages";
import { QRCodeReader } from "..";
import './FastConnect.css';

type FastConnectProps = {
    appTools: AppTools,
    cancel: () => void,
    connected: (token: string, identifier: string) => void
}

const FastConnect = (props: FastConnectProps) => {
    const { appTools, cancel, connected } = props;

    const [state, privateTryConnect, isExistUserId, , cancelConnect] = useConnect(() => {})
    const mainRef = createRef<HTMLDivElement>();

    const triggerHideReader = (afterClose:() => void) => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            afterClose();
        },400);
    }

    const onQRCodeRead = (text: string) => {
            text = tryGetValueOfQrCode(text);
            appTools.isLoading(true);
            isExistUserId!(text)
            .then(() => {
                // il n'y a rien à faire, la fonction 'isExistUserId'
                // va changer l'état de connexion et App.tsx se charge de 
                // changer de page 
            })
            .catch(() => {
    
            })
            .finally(() => {
                appTools.isLoading(false);
            })
        };

    const tryConnect = async (pin: string): Promise<boolean> => {
        try {
            const token = await privateTryConnect(pin);
            triggerHideReader(() => connected(token.token, token.identifier));
            return true;
        } catch (error) {
            return false;
        }
    }

    return <div className="fastConnect-main" ref={mainRef}>
        {state === ConnectState.NotConnected && (
             <QRCodeReader
                valueToIncludeToValidateQrCode={config.appNameDomain}
                description="Flasher le QR Code associé à votre profil"
                handleQrCodeRead={onQRCodeRead} 
                onGoBack={() => {
                    triggerHideReader(() => cancel())
                }}
            />
        )}
        {state === ConnectState.HaveUserId && (
            <EnterPin 
                appTools={appTools}
                tryConnect={tryConnect}
                cancelConnect={cancelConnect}
            />
        )}
    </div>
}

export default FastConnect;