import { useEffect } from "react";
import Cookies from "universal-cookie";
import { COOKIES_TOKEN, LOCAL_STORAGE_IDENTIFIER } from '../const.ts';
import {useConnect} from ".";
import { ApiError } from "../api/responses.ts";

const cookies = new Cookies(null, { path: '/' });

const useConnectWithCookies = (onTokenExpire: () => void) => {

    const onTokenExpireTriggered = () => {
        cookies.remove(COOKIES_TOKEN);
        onTokenExpire();
    }

    const [state, privateTryConnect, isExistUserId, privateDisconnect, cancelConnect, reloadExpirationTimer, checkTokenValid] = useConnect(onTokenExpireTriggered);
    
    useEffect(() => {
        checkConnect().then((res) => {
            if(!res){
                cookies.remove(COOKIES_TOKEN);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkConnect = () : Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if(cookies.get(COOKIES_TOKEN)){
                checkTokenValid(cookies.get(COOKIES_TOKEN)).then((res) => {
                    resolve(res);
                });
            }else{
                resolve(false);
            }
        });
    }

    const tryConnect = (pin: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            privateTryConnect(pin).then((token) => {
                cookies.set(COOKIES_TOKEN, token.token);
                resolve(true);
            }).catch((err) => {
                const apiErr: ApiError = err as ApiError
                if(apiErr.code === 403){
                    resolve(false); 
                }else{
                    reject(apiErr);
                }
            });
        });
    }

    const disconnect = () : Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            privateDisconnect(cookies.get(COOKIES_TOKEN)).then(() => {
                cookies.remove(COOKIES_TOKEN);
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }

    return [state, tryConnect, isExistUserId, disconnect, cancelConnect, reloadExpirationTimer, checkConnect] as const;
}

export default useConnectWithCookies;