import React, { createRef, useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { COOKIES_TOKEN, LOCAL_STORAGE_IDENTIFIER } from "../../const.ts";
import { FastConnect, GoBack, PdfReader } from "..";
import config from "../../config.ts";
import './FormDisplay.css';
import { AppTools } from "../../types.ts";
import { WorkOrderResponse } from "../../api/responses.ts";
import { PdfImg } from "../../images/index.js";

const cookies = new Cookies(null, { path: '/' });

type FormDisplayProps = {
    workOrder: WorkOrderResponse,
    onClose: () => void,
    appTools: AppTools,
    customToken?: string
}

const formUrlEndPoint = "/permis";

const FormDisplay = (props: FormDisplayProps) => {
    const { workOrder, onClose, appTools, customToken } = props;
    const mainRef = createRef<HTMLDivElement>();
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [nbGetState, setNbGetState] = useState<number>(0);

    const triggerHideReader = () => {
        mainRef.current!.style.animationName = "slideout";
        // on prévient le formulaire qu'on va le fermer
        iframeRef.current?.contentWindow?.postMessage({closingWindow: true}, '*');
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current.style.opacity = "0";
            }
            onClose();
        },400);
    }

    const openPdfWorkOrder = (pdfToDisplay: string) => {
        appTools.pushDisplay(<PdfReader 
            customToken={customToken}
            appTools={appTools}
            fileName={pdfToDisplay} 
            onClose={() => appTools.popDisplay()} />)
    };

    const triggerDisplayScanner = () => {
        appTools.pushDisplay(
            <FastConnect 
            appTools={appTools} 
            cancel={() => appTools.popDisplay()}
            connected={(token, identified) => {
                sendToken(token, identified);
                appTools.popDisplay();
            }}
            />
        )
    }

    const sendToken = (accessToken: string, identifier: string | null) => {
        iframeRef.current?.contentWindow?.postMessage(
            { 
                accessToken: accessToken, 
                identifier: identifier
            }
            , '*');
    }

    useEffect(() => {
        const triggerSendToken = () => {
            setTimeout(() => {
                sendToken(
                    cookies.get(COOKIES_TOKEN), 
                    localStorage.getItem(LOCAL_STORAGE_IDENTIFIER));
            }, 1000);
        }

        const triggerChangeProfile = (e: MessageEvent<string>) => {
            if(e.data && e.data === 'changeProfile'){
                triggerDisplayScanner();
            }
        }

        window.addEventListener('message', triggerChangeProfile);
        iframeRef.current!.addEventListener('load', triggerSendToken);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            iframeRef.current?.removeEventListener('load', triggerSendToken);
            window.removeEventListener('message', triggerChangeProfile);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // envoie une requête toute les 10 secs afin d'éviter
    // que le token expire. 
    // 💬 Il est difficile de faire communiquer le frontEndExt 
    // de Petra et ce frontEnd. 
    useEffect(() => {
        setTimeout(() => {
            appTools.checkUserConnected();
            setNbGetState(prev => prev + 1);
        }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nbGetState])

    return (
        <div ref={mainRef} className="formDisplay-main"> 
            <GoBack onGoBack={triggerHideReader} />

            <iframe title="éditeur formulaire" ref={iframeRef} className="formDisplay-container"
                src={`${config.petraFrontUrl}${formUrlEndPoint}/${workOrder.workId}`} />

            <div className="formDisplay-footer">
                <div className='petra-button wodetail-button-icon' onClick={() => openPdfWorkOrder(workOrder.workPDF!)}>
                    <img alt="Permis en format PDF" src={PdfImg} /> <p>{workOrder.workLabelle}</p>
                </div>
            </div>
        </div>
    );
}

export default FormDisplay;